import React from 'react';
import logo from './logo.png';
import './Construct.css';
import axios from "axios";

function App() {

    axios.get('https://natwebdev.com/api').then((res)=>{
        console.log(res);
    })

  return (
    <div className="App">
      <div>
        <img src={logo} />
      </div>
    </div>
  );
}

export default App;
